import { HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BaseAuthRefreshInterceptor } from '@kauth';
import { EnvironmentVariablesService } from '@kenv';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { UserBloc } from '@ki/user/user.bloc';
import { AuthDataService, DataStoreService } from '@kservice';

@Injectable()
export class AuthRefreshInterceptor extends BaseAuthRefreshInterceptor {
  constructor(
    protected _authDataService: AuthDataService,
    protected _authenticationBloc: AuthenticationBloc,
    protected _dataStoreService: DataStoreService,
    protected _environmentVariablesService: EnvironmentVariablesService,
    protected _router: Router,
    protected _userBloc: UserBloc
  ) {
    super(_authDataService, _authenticationBloc, _dataStoreService, _environmentVariablesService, _router, _userBloc);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    return super.intercept(req, next);
  }
}
