import { UserResponseMetricType } from '../enums';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { UserResponseMetricLabel } from './user-response-metric-label.model';
import { UserResponseMetricTooltip } from './user-response-metric-tooltip.model';
import { UserResponseStatistic } from './user-response-statistic.model';

export class UserResponseMetric implements Deserializable<JsonObject, UserResponseMetric> {
  constructor(
    public metricType?: UserResponseMetricType,
    public metrics?: UserResponseStatistic[],
    public label?: UserResponseMetricLabel,
    public tooltip?: UserResponseMetricTooltip
  ) {}

  deserialize(input: JsonObject): UserResponseMetric {
    if (input == null) {
      return null;
    }
    if (Array.isArray(input['metrics'])) {
      this.metrics = input['metrics'].map((metric) => new UserResponseStatistic().deserialize(metric));
    }
    this.metricType = input['metricType'] as UserResponseMetricType;
    this.label = new UserResponseMetricLabel().deserialize(input['label']);
    this.tooltip = new UserResponseMetricTooltip().deserialize(input['tooltip']);

    return this;
  }
}
