import { Component, OnInit } from '@angular/core';
import { AnalyticEvent, AnalyticsBloc, AnalyticsPageName } from '@kanalytics';
import { InsightfulDataStoreService } from '@ki/core/data-store.service';
import { OgConfiguration } from '@ki/shared/components/og-selector/og-selector';
import { Option } from '@ki/shared/models/option';
import { FilterSelectorsBloc } from '@ki/shared/services/filter-selectors.bloc';
import { EligibilityInclusionOption } from '@ktypes/enums';

@Component({
  selector: 'ki-eligibility-selector',
  templateUrl: './eligibility-selector.component.html',
  styleUrls: ['./eligibility-selector.component.scss'],
})
export class EligibilitySelectorComponent implements OnInit {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _dataStoreService: InsightfulDataStoreService,
    private _filterSelectorsBloc: FilterSelectorsBloc
  ) {}

  config: OgConfiguration = {
    color: 'transparent',
    leadIcon: '../assets/icons/eligibility-filter.svg',
    dropIcon: true,
  };
  hasEligibility = true;
  eligibilityStatuses: Option[] = [
    { name: EligibilityInclusionOption.currently_eligible, value: 'Currently Eligible' },
    { name: EligibilityInclusionOption.all_time_eligible, value: 'Ever Eligible' },
  ];
  selectedOption: EligibilityInclusionOption;

  ngOnInit() {
    this.selectedOption =
      this._dataStoreService.selectedFilters?.selectedEligibilityStatus ||
      EligibilityInclusionOption.currently_eligible;
  }

  optionsChanged(event) {
    this._analyticsBloc.sendEvent(
      this._analyticsBloc.createEventFromInteraction({
        page: AnalyticsPageName.dashboard,
        event: AnalyticEvent.eligibility_inclusion_option_switched,
        meta: { event },
      })
    );
    this._filterSelectorsBloc.updateEligibilityStatusFilter(event);
  }
}
