import cloneDeep from 'lodash/cloneDeep';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class CardTitle implements Deserializable<JsonObject, CardTitle> {
  constructor(
    public id?: string,
    public title?: string
  ) {}

  clone(card: CardTitle) {
    return cloneDeep(card);
  }

  deserialize(json: JsonObject) {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.title = json['title'] as string;

    return this;
  }
}
