import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OgConfiguration } from '@ki/shared/components/og-selector/og-selector';
import { Option } from '@ki/shared/models/option';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'ki-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DropdownComponent {
  @Input() config: OgConfiguration = {
    color: 'transparent',
    leadIcon: null,
    dropIcon: true,
    borderColor: '',
  };
  @Input() options: Option[];
  // Note: selectedOption is a string not an Option
  @Input() selectedOption: string;
  @Input() semanticLabel: string;
  @Output() optionChanged: EventEmitter<string> = new EventEmitter<string>();

  optionsChanged(targetEl) {
    const id = targetEl.value;
    this.selectedOption = id;
    this.optionChanged.emit(id);
  }
}

export const MockDropdownComponent = MockComponent({
  selector: 'ki-dropdown',
  inputs: ['config', 'options', 'selectedOption', 'semanticLabel'],
});
