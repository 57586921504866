import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserResponseMetricLabel implements Deserializable<JsonObject, UserResponseMetricLabel> {
  constructor(
    public denominatorLabel?: string,
    public numeratorLabel?: string
  ) {}

  deserialize(input: JsonObject): UserResponseMetricLabel {
    if (input == null) {
      return null;
    }

    this.denominatorLabel = input['denominatorLabel'] as string;
    this.numeratorLabel = input['numeratorLabel'] as string;
    return this;
  }
}
