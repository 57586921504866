import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AnalyticsBloc } from '@kanalytics';
import { HeartbeatApi } from '@kapi';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService } from '@kservice';
import { PaymentRequiredStatusMessage, UserType } from '@ktypes/enums';
import { EnumUtil } from '@kutil';
import { Observable, combineLatest, of, withLatestFrom } from 'rxjs';
import { catchError, filter, mergeMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AuthenticatedGuard {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authenticationBloc: AuthenticationBloc,
    private _dataStoreService: DataStoreService,
    private _heartbeatApi: HeartbeatApi,
    private _router: Router,
    private _userBloc: UserBloc
  ) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return combineLatest([this._userBloc.fetchingUser$, this._dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      withLatestFrom(this._authenticationBloc.currentPaymentRequiredErrorMessage$),
      mergeMap(([[_, user], paymentRequiredErrorMessage]) => {
        if (paymentRequiredErrorMessage) {
          if (EnumUtil.getByValue(PaymentRequiredStatusMessage, paymentRequiredErrorMessage)) {
            return of(true);
          }
          this._authenticationBloc.logout(false);
          void this._router.navigate(['/login'], {
            queryParams: {
              redirectUrl: encodeURIComponent(state.url),
            },
          });
          return of(false);
        }

        if (user?.type === UserType.user) {
          // no need to check isTokenExpiredLocally() as the heartbeat call will try to auto-refresh the token if expired
          return this._heartbeatApi.isValid$(true).pipe(
            tap((isValid) => {
              if (isValid) {
                // side effect, if valid, means auth is good, can allow analytics to begin
                this._analyticsBloc.userReadyForAnalytics();
              } else {
                console.error('logged in by invalid credentials');
                this._handleAuthError(new Error('authentication is invalid'), state);
              }
            }),
            catchError((err) => this._handleAuthError(err, state))
          );
        }

        void this._router.navigate(['/login'], {
          queryParams: {
            redirectUrl: encodeURIComponent(state.url),
          },
        });
        return of(false);
      })
    );
  }

  private _handleAuthError(err: any, state: RouterStateSnapshot) {
    if (err != null) {
      console.warn(err);
    }
    this._authenticationBloc.logout(false);
    void this._router.navigate(['/login'], {
      queryParams: {
        redirectUrl: encodeURIComponent(state.url),
      },
    });
    return of(false);
  }
}
