import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserResponseStatistic implements Deserializable<JsonObject, UserResponseStatistic> {
  constructor(
    public keyword?: string,
    public denominator?: string,
    public numerator?: string,
    public percent?: number,
    public rank?: string
  ) {}

  deserialize(input: JsonObject): UserResponseStatistic {
    if (input == null) {
      return null;
    }

    this.keyword = input['keyword'] as string;
    this.denominator = input['denominator'] as string;
    this.numerator = input['numerator'] as string;
    this.percent = input['percentage'] as number;
    this.rank = input['rank'] as string;
    return this;
  }
}
