import { ContentImage } from './content-image.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export interface AdminPromoApiResponse {
  id: string;
  contentId: string;
  title: string;
  label: string;
  summary: string;
  image: JsonObject;
  callToAction: string;
  isFeatured: boolean;
  dismissible: boolean;
  priority: number;
  expirationDate: string;
}

export class AdminPromo implements Deserializable<AdminPromoApiResponse, AdminPromo> {
  constructor(
    public id?: string,
    public contentId?: string,
    public title?: string,
    public label?: string,
    public summary?: string,
    public image?: ContentImage,
    public callToAction?: string,
    public isFeatured?: boolean,
    public dismissible?: boolean,
    public priority?: number,
    public expirationDate?: Date
  ) {}

  deserialize(json: AdminPromoApiResponse): AdminPromo {
    if (json == null) {
      return null;
    }

    this.id = json['id'];
    this.contentId = json['contentId'];
    this.title = json['title'];
    this.label = json['label'];
    this.summary = json['summary'];
    this.image = new ContentImage().deserialize(json['image']);
    this.callToAction = json['callToAction'];
    this.isFeatured = !!json['isFeatured'];
    this.dismissible = !!json['dismissible'];
    this.priority = json['priority'];
    if (json['expirationDate']) {
      this.expirationDate = new Date(json['expirationDate']);
    }

    return this;
  }

  isExpired(): boolean {
    return this.expirationDate && this.expirationDate < new Date();
  }
}
