import { ErrorHandler, NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule, Title } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PAGE_MAPPING } from '@kanalytics';
import { NAV_ITEM_LIST } from '@kbloc';
import { CONFIG } from '@kenv';
import { environment } from '@ki/../environments/environment';
import { AppRoutingModule } from '@ki/app-routing.module';
import { AppComponent } from '@ki/app.component';
import { CoreModule } from '@ki/core/core.module';
import { pageMappingData } from '@ki/core/page-mapping-info';
import { httpInterceptorProviders } from '@ki/http-interceptors';
import { SentryErrorHandler } from '@ki/sentry/sentry.service';
import { Constants } from '@ki/shared/constants.service';
import { SharedModule } from '@ki/shared/shared.module';
import { WINDOW_PROVIDERS } from '@kservice';
import { TooltipVariations } from '@ktypes/props';
import { VersionTagComponent } from '@kui';
import { TIPPY_CONFIG, popperVariation, tooltipVariation } from '@ngneat/helipopper';

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    BrowserModule,
    CoreModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    SharedModule,
    VersionTagComponent,
  ],
  providers: [
    WINDOW_PROVIDERS,
    Title,
    httpInterceptorProviders,
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: CONFIG, useValue: environment },
    { provide: NAV_ITEM_LIST, useValue: Constants.navItemList },
    { provide: PAGE_MAPPING, useValue: pageMappingData },
    {
      provide: TIPPY_CONFIG,
      useValue: {
        defaultVariation: 'insightful',
        variations: {
          tooltip: tooltipVariation,
          popper: popperVariation,
          insightful: TooltipVariations.insightful,
          insightfulInfo: TooltipVariations.insightfulInfo,
        },
      },
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
