/* eslint-disable */
// NOTE :: DO NOT EDIT :: this file is automatically generated by generate-environment-variables.js node script

export const envVariables: { [key: string]: string } = {
  CIRCLE_TAG: 'prod-insightful-v42',
  PROJECT: 'insightful',
};
export const latestTag: string = 'prod-insightful-v42';
export const latestVersion: string = 'v42';
export const versions: { app: { [key: string]: string }, branch: string, npm_version: string, revision: string } = {
  app: {
    purposeful: '6.5.1',
    insightful: '2.8.2',
  },
  branch: 'HEAD',
  npm_version: '160.0.0',
  revision: 'ae7e1e940',
};
/* eslint-enable */
