export enum OgSelector {
  organization = 'organization',
  group = 'group',
  all = 'all',
}

export interface OgEvent {
  id?: string;
  type: OgSelector;
}

export interface OgConfiguration {
  color: string;
  leadIcon: string;
  dropIcon?: boolean;
  borderColor?: string;
}
