import { Injectable } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService } from '@kservice';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class RoleGuard {
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _dataStoreService: DataStoreService,
    private _router: Router,
    private _userBloc: UserBloc
  ) {}

  canMatch(route: Route /* , segments: UrlSegment[] */): boolean | Observable<boolean | UrlTree> {
    // If there are no required roles, allow the navigation
    if (route?.data?.requiredRoles == null || route.data.requiredRoles.length === 0) {
      return true;
    }

    return combineLatest([this._userBloc.fetchingUser$, this._dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      map(([_, user]) => {
        // Require all required roles to be present to be a match
        const roleMatched = route.data?.requiredRoles?.every?.((requiredRole) =>
          user?.roles?.map((role) => role?.key?.toLowerCase())?.includes(requiredRole.toLowerCase())
        );
        if (!roleMatched) {
          this._authenticationBloc.logout(false);
        }
        // defaults to blocking navigation, redirect to error page
        return roleMatched || this._router.parseUrl('/error/denied');
      })
    );
  }
}
