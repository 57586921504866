import { NgModule } from '@angular/core';
import { ExtraOptions, mapToCanActivate, mapToCanMatch, RouterModule, Routes, TitleStrategy } from '@angular/router';
import { AuthSsoLogoutComponent, CodeComponent } from '@kauth';
import { AuthenticatedGuard } from '@ki/auth/guards/authenticated.guard';
import { FeatureGuard } from '@ki/auth/guards/feature.guard';
import { NotAuthenticatedGuard } from '@ki/auth/guards/not-authenticated.guard';
import { RoleGuard } from '@ki/auth/guards/role.guard';
import { ScreenLogoutComponent } from '@ki/screen-logout/screen-logout.component';
import { TitleStrategyDefaultService } from '@kservice';
import { Feature, Role } from '@ktypes/enums';
import { ScreenSiteMapComponent } from '@kui';

const routerOptions: ExtraOptions = {
  anchorScrolling: 'enabled',
  // enableTracing: true, // <-- debugging purposes only
};

const routes: Routes = [
  {
    path: 'auth/sso',
    component: CodeComponent,
  },
  {
    path: 'auth/sso/logout',
    component: AuthSsoLogoutComponent,
  },
  {
    path: 'login',
    loadChildren: () => import('./screen-login/screen-login.module').then((mod) => mod.ScreenLoginModule),
  },
  {
    path: 'logout',
    component: ScreenLogoutComponent,
    title: 'Logout',
  },
  {
    path: 'error',
    loadChildren: () => import('./screen-error/screen-error.module').then((mod) => mod.ScreenErrorModule),
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./screen-dashboard/screen-dashboard.module').then((mod) => mod.ScreenDashboardModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
    canMatch: mapToCanMatch([FeatureGuard]),
    data: { requiredFeatures: [Feature.INSIGHTFUL] },
  },
  {
    path: 'detail',
    loadChildren: () => import('./screen-dashboard/screen-dashboard.module').then((mod) => mod.ScreenDashboardModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
    canMatch: mapToCanMatch([FeatureGuard]),
    data: { requiredFeatures: [Feature.INSIGHTFUL] },
  },
  {
    path: 'reports',
    loadChildren: () => import('./screen-reports/screen-reports.module').then((mod) => mod.ScreenReportsModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
    canMatch: mapToCanMatch([FeatureGuard]),
    data: { requiredFeatures: [Feature.INSIGHTFUL] },
  },
  {
    path: 'admin',
    loadChildren: () => import('./screen-admin/screen-admin.module').then((mod) => mod.ScreenAdminModule),
    canActivate: mapToCanActivate([AuthenticatedGuard]),
    canMatch: mapToCanMatch([FeatureGuard, RoleGuard]),
    data: {
      requiredFeatures: [Feature.INSIGHTFUL],
      requiredRoles: [Role.INSIGHTFUL_ADMIN],
    },
  },
  {
    path: 'site-map',
    component: ScreenSiteMapComponent,
    title: 'Site Map',
  },
  {
    path: '',
    pathMatch: 'full',
    // need empty children array to allow for canActivate;
    // need one of component, children or redirectTo defined per Angular spec
    children: [],
    canActivate: mapToCanActivate([NotAuthenticatedGuard]),
  },
  { path: '**', redirectTo: '/error/notFound' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, routerOptions)],
  exports: [RouterModule],
  providers: [{ provide: TitleStrategy, useClass: TitleStrategyDefaultService }],
})
export class AppRoutingModule {}
