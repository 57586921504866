<div class="selectBordered {{ config.color }} {{ config.borderColor }}">
  @if (config.leadIcon) {
    <svg-icon class="leadingIcon navIcon" src="{{ config.leadIcon }}" title="{{ semanticLabel }} Dropdown"></svg-icon>
  }
  <div class="dropIcon {{ config.color }}">
    <svg-icon class="dropChevron navIcon dropIcon chevron" src="/assets/controls/chevron_right.svg"></svg-icon>
  </div>
  <select class="{{ config.color }}" id="options" (change)="optionsChanged($event.target)">
    @for (option of options; track option) {
      <option [value]="option.name" [selected]="option.name === selectedOption">
        {{ option.value }}
      </option>
    }
  </select>
</div>
