import { HttpClient, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentVariablesService } from '@kenv';
import { DataStoreService } from '@kservice';
import { DataStatus, Group, Status, StatusMessage } from '@ktypes/models';
import { firstValueFrom } from 'rxjs';
import { map } from 'rxjs/operators';
import { BaseApi, RequestType } from './base.api';

@Injectable({
  providedIn: 'root',
})
export class GroupApi extends BaseApi {
  constructor(
    private _client: HttpClient,
    dataStoreService: DataStoreService,
    private _environmentVariablesService: EnvironmentVariablesService
  ) {
    super(_client, dataStoreService, _environmentVariablesService);
  }

  getGroupById(groupId: string): Promise<DataStatus<Group> | HttpErrorResponse> {
    const url = this.buildUrl(`/group/${groupId}`);
    const request = this.performRequest<Group>(RequestType.GET, url).pipe(
      map((response: HttpResponse<Group>): DataStatus<Group> => {
        if (response?.ok) {
          return new DataStatus(
            Status.done,
            new StatusMessage(response.status, ''),
            new Group().deserialize(response.body)
          );
        }
        return new DataStatus<Group>(
          Status.error,
          new StatusMessage(response.status, `Error getting group for "${groupId}"`),
          null
        );
      })
    );

    return firstValueFrom(request).catch((error: HttpErrorResponse): HttpErrorResponse => {
      console.warn(`Error getting group for "${groupId}": `, error);
      return error;
    });
  }

  getGroupByCode(groupCode: string): Promise<DataStatus<Group> | HttpErrorResponse> {
    const url = this.buildUrl('/group', false, { queryParams: { groupCode } });
    const request = this.performRequest(RequestType.GET, url).pipe(
      map((response): DataStatus<Group> => {
        if (response?.ok) {
          return new DataStatus(
            Status.done,
            new StatusMessage(response.status, ''),
            new Group().deserialize(response.body)
          );
        }
        return new DataStatus<Group>(
          Status.error,
          new StatusMessage(response.status, `Error getting group for "${groupCode}"`),
          null
        );
      })
    );
    return firstValueFrom(request).catch((error: HttpErrorResponse) => {
      console.warn(`Error getting group for "${groupCode}": `, error);
      return error;
    });
  }
}
