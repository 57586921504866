import { Injectable, OnDestroy } from '@angular/core';
import { OgSelector } from '@ki/shared/components/og-selector/og-selector';
import { BrowserStorage, DataStoreService } from '@kservice';
import { EligibilityInclusionOption, FilterTime, HttpStatusCode, Role, UserResponseMetricType } from '@ktypes/enums';
import {
  CardTitle,
  DataStatus,
  Group,
  Insight,
  Metric,
  Organization,
  OverTimeMetric,
  Report,
  Status,
  StatusMessage,
  UserResponseMetric,
} from '@ktypes/models';
import { isOfType } from '@kutil';
import cloneDeep from 'lodash/cloneDeep';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

interface OGMap<OGType = Group | Organization> {
  [s: string]: OGType;
}

export interface InsightfulStore {
  engagement?: DataStatus<Metric[]>;
  insightsStatus?: DataStatus<Insight[]>;
  overTimeMetrics?: DataStatus<OverTimeMetric[]>;
  reportsStatus?: DataStatus<Report[]>;
  groups?: OGMap<Group>;
  organizations?: OGMap<Organization>;
  responseMetrics?: Map<UserResponseMetricType, DataStatus<UserResponseMetric>>;
  cards?: CardTitle[];
  cardDynamicLinks?: Record<string, string>;
}

export interface SelectedFilter {
  mostRecentlyUpdatedFilter?: SelectedFilter | FilterTime;
  selectedGroupId?: string;
  selectedOrganizationId?: string;
  selectedFilterType?: OgSelector;
  selectedFilterTimeframe?: FilterTime;
  selectedEligibilityStatus?: EligibilityInclusionOption;
  selectedOvertimeMetric?: string;
  selectedOvertimeTimeframe?: string;
  selectedUserResponseMetric?: UserResponseMetricType;
  selectedWhatMattersMostMetric?: UserResponseMetricType;
  userId?: string;
}

@Injectable({
  providedIn: 'root',
})
export class InsightfulDataStoreService implements OnDestroy {
  private _store: InsightfulStore = {};
  private _store$: BehaviorSubject<InsightfulStore>;
  private _selectedFilters: SelectedFilter = {};
  private _selectedFilters$: BehaviorSubject<SelectedFilter>;

  constructor(
    private _browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService
  ) {
    this._store = {
      insightsStatus: new DataStatus<Insight[]>(
        Status.starting,
        new StatusMessage(HttpStatusCode.OK, 'Initial data'),
        []
      ),
      reportsStatus: new DataStatus<Report[]>(
        Status.starting,
        new StatusMessage(HttpStatusCode.OK, 'Initial data'),
        []
      ),
      groups: {},
      organizations: {},
      responseMetrics: new Map<UserResponseMetricType, DataStatus<UserResponseMetric>>([
        [UserResponseMetricType.strengths, null],
        [UserResponseMetricType.risks, null],
        [UserResponseMetricType.roles, null],
        [UserResponseMetricType.interests, null],
        [UserResponseMetricType.focuses, null],
        [UserResponseMetricType.bestSelfTraits, null],
      ]),
      cardDynamicLinks: {},
    };
    this._store$ = new BehaviorSubject<InsightfulStore>(this._store);
    this._selectedFilters = {} as SelectedFilter;
    this._selectedFilters$ = new BehaviorSubject<SelectedFilter>(this._selectedFilters);
  }

  ngOnDestroy(): void {
    this._store$.complete();
    this._selectedFilters$.complete();
  }

  get selectedUserResponseMetric$() {
    return this.selectedFilters$.pipe(
      map((filters) => {
        return filters.selectedUserResponseMetric;
      })
    );
  }

  get selectedFilters(): SelectedFilter {
    // return a clone of the selectedFilters, so the underlying data cannot be
    // manipulated outside the methods available in this service
    return cloneDeep(this._selectedFilters);
  }
  get selectedFilters$(): Observable<SelectedFilter> {
    return this._selectedFilters$.asObservable();
  }
  setSelectedFilter(data: SelectedFilter | FilterTime);
  setSelectedFilter(data: string, type?: OgSelector, updateStream?: boolean);
  setSelectedFilter(
    data: string | FilterTime | SelectedFilter,
    type?: OgSelector,
    updateStream = true
  ): SelectedFilter {
    const firstOrgId = Object.keys(this._store.organizations || {})[0];
    if (type == null && typeof data !== 'string' && Object.keys(data || {}).length > 0) {
      const filterData = data as SelectedFilter;
      this._selectedFilters = {
        mostRecentlyUpdatedFilter: filterData,
        selectedFilterType: this._getSelectedFilterType(filterData),
        selectedGroupId: this._getSelectedGroupId(filterData),
        selectedOrganizationId: this._getSelectedOrganizationId(filterData, firstOrgId),
        selectedFilterTimeframe: this._getSelectedFilterTimeframe(filterData),
        selectedEligibilityStatus: this._getSelectedEligibilityStatus(filterData),
        selectedOvertimeMetric: this._getSelectedOverTimeMetric(filterData),
        selectedOvertimeTimeframe: this._getSelectedOvertimeTimeframe(filterData),
        selectedUserResponseMetric: this._getSelectedUserResponseMetric(filterData),
        selectedWhatMattersMostMetric: this._getSelectedWhatMattersMostMetric(filterData),
        userId: this._dataStoreService.user?.id,
      };
    } else {
      const _data: string = FilterTime[data as string] == null ? (data as string) : null;
      const _filterData: FilterTime = FilterTime[data as string] != null ? (data as FilterTime) : null;
      this._selectedFilters = {
        selectedFilterType: (_data && type) || this._selectedFilters.selectedFilterType,
        selectedGroupId: this._getSelectedOrDefaultGroup(_data, type),
        selectedOrganizationId: _data
          ? this._getOrgId(_data, type)
          : this._selectedFilters.selectedOrganizationId || firstOrgId,
        selectedFilterTimeframe: _filterData || this._selectedFilters.selectedFilterTimeframe,
        selectedEligibilityStatus: this._selectedFilters.selectedEligibilityStatus,
        selectedOvertimeMetric: _filterData || this._selectedFilters.selectedOvertimeMetric,
        selectedOvertimeTimeframe: _filterData || this._selectedFilters.selectedOvertimeTimeframe,
        selectedUserResponseMetric: this._selectedFilters.selectedUserResponseMetric,
        selectedWhatMattersMostMetric: this._selectedFilters.selectedWhatMattersMostMetric,
        mostRecentlyUpdatedFilter: _filterData,
        userId: this._dataStoreService.user?.id,
      };
    }
    if (
      this._selectedFilters.mostRecentlyUpdatedFilter != null &&
      isOfType<SelectedFilter, FilterTime>(this._selectedFilters.mostRecentlyUpdatedFilter, 'mostRecentlyUpdatedFilter')
    ) {
      // don't allow mostRecentlyUpdatedFilter to nest
      delete this._selectedFilters.mostRecentlyUpdatedFilter.mostRecentlyUpdatedFilter;
    }
    this.ensureFilterSelectionsAlign();
    if (updateStream) {
      this._selectedFilters$.next(this._selectedFilters);
    }
    this._browserStorage.setObject('selectedFilters', this._selectedFilters);
    return this._selectedFilters;
  }

  ensureFilterSelectionsAlign() {
    if (this.organizations?.length === 0) {
      // exit if called before there are organizations
      return;
    }
    const orgId = this._selectedFilters.selectedOrganizationId;
    const groupId = this._selectedFilters.selectedGroupId;
    const selectedOrg = this.organizations.find((org) => org.id === orgId);
    const selectedGroup = (selectedOrg?.groups as Group[])?.find((group) => group.id === groupId);
    const isAdmin = this._dataStoreService.user.roles.some((role) => role.key === Role.INSIGHTFUL_ADMIN);

    // ensure organization and group exist and align with each other
    // (i.e. selected group is in selected organization)
    if (orgId !== 'all') {
      if (!selectedOrg || !this.hasAccess(orgId)) {
        // ensure if organization is specified in selectedFilters, it exists and user has access or just reset both to 'all'
        this._selectedFilters.selectedOrganizationId = isAdmin ? 'all' : this.getDefaultOrg();
        this._selectedFilters.selectedGroupId = 'all';
      }
      if (groupId !== 'all' && (!selectedGroup || !this.groupHasAccess(groupId))) {
        // ensure if group is specified in selectedFilters, it exists in the organization and user has access or reset group to 'all'
        this._selectedFilters.selectedGroupId = 'all';
      }
    } else if (!this._dataStoreService.user.roles.some((role) => role.key === Role.INSIGHTFUL_ADMIN)) {
      this._selectedFilters.selectedOrganizationId = this.getDefaultOrg();
      this._selectedFilters.selectedGroupId = 'all';
    }
  }

  private _getSelectedFilterType(filterData: SelectedFilter) {
    return filterData.selectedFilterType || this._selectedFilters.selectedFilterType;
  }

  private _getSelectedOrganizationId(filterData: SelectedFilter, firstOrgId: string) {
    return filterData.selectedOrganizationId || this._selectedFilters.selectedOrganizationId || firstOrgId;
  }

  private _getSelectedGroupId(filterData: SelectedFilter) {
    return filterData.selectedGroupId || this._selectedFilters.selectedGroupId || this.getDefaultGroup();
  }

  private _getSelectedOverTimeMetric(filterData: SelectedFilter) {
    return filterData.selectedOvertimeMetric || this._selectedFilters.selectedOvertimeMetric;
  }

  private _getSelectedOvertimeTimeframe(filterData: SelectedFilter) {
    return filterData.selectedOvertimeTimeframe || this._selectedFilters.selectedOvertimeTimeframe;
  }

  private _getSelectedFilterTimeframe(filterData: SelectedFilter) {
    return filterData.selectedFilterTimeframe || this._selectedFilters.selectedFilterTimeframe;
  }

  private _getSelectedEligibilityStatus(filterData: SelectedFilter) {
    return (
      (filterData.selectedEligibilityStatus || this._selectedFilters.selectedEligibilityStatus) ??
      EligibilityInclusionOption.currently_eligible
    );
  }

  private _getSelectedUserResponseMetric(filterData: SelectedFilter) {
    return filterData.selectedUserResponseMetric || this._selectedFilters.selectedUserResponseMetric;
  }

  private _getSelectedWhatMattersMostMetric(filterData: SelectedFilter) {
    return filterData.selectedWhatMattersMostMetric || this._selectedFilters.selectedWhatMattersMostMetric;
  }

  private _getSelectedOrDefaultGroup(possibleGroupId: string, type: OgSelector): string {
    if (type !== OgSelector.group) {
      return this._selectedFilters.selectedGroupId;
    } else if (possibleGroupId) {
      return possibleGroupId;
    } else if (
      this.hasAccess(this._selectedFilters.selectedOrganizationId) ||
      this._selectedFilters.selectedOrganizationId === OgSelector.all
    ) {
      return 'all';
    }
    return this.getDefaultGroup();
  }

  private _setFiltersForSingleOrgOrGroup(ogs: OGMap, ogId: string, ogSelector: OgSelector) {
    if (!ogId) {
      if (Object.keys(ogs || {}).length === 1) {
        this.setSelectedFilter(Object.keys(ogs || {})[0], ogSelector, false);
      } else {
        this.setSelectedFilter('all', ogSelector, false);
      }
    }
  }

  private _getOrgId(id: string, type: OgSelector) {
    if (type === OgSelector.organization) {
      return id;
    } else if (type === OgSelector.group || Object.values(this._store.organizations || []).length === 1) {
      return this._selectedFilters.selectedOrganizationId;
    }
    return 'all';
  }

  getDefaultOrg(): string {
    return Object.keys(this._store.organizations || {})[0];
  }

  getDefaultGroup(): string {
    return Object.keys(this._store.groups || {})[0];
  }

  getEngagement$(): Observable<DataStatus<Metric[]>> {
    return this._store$.pipe(map((store) => store.engagement));
  }

  setEngagement(engagementData: Metric[], status: Status, updateStream = true) {
    this._store = {
      ...this._store,
      engagement: new DataStatus<Metric[]>(status, engagementData),
    };
    if (updateStream) {
      this._store$.next(this._store);
    }
  }

  getOverTimeMetrics$(): Observable<DataStatus<OverTimeMetric[]>> {
    return this._store$.pipe(map((store) => store.overTimeMetrics));
  }

  setOverTimeMetrics(overTimeData: OverTimeMetric[], status: Status, updateStream = true) {
    this._store = {
      ...this._store,
      overTimeMetrics: new DataStatus<OverTimeMetric[]>(status, overTimeData),
    };
    if (updateStream) {
      this._store$.next(this._store);
    }
  }

  setResponseMetrics(responseMetricData: UserResponseMetric[], status: Status, metricTypes: UserResponseMetricType[]) {
    // Shallow copy map entries that is in store into a new map
    const newResponseMetrics = copyMapEntries<UserResponseMetricType, DataStatus<UserResponseMetric>>(
      this._store.responseMetrics
    );

    metricTypes?.forEach((metricType) => {
      if (!metricType) {
        return;
      }
      newResponseMetrics.set(
        metricType,
        new DataStatus<UserResponseMetric>(
          status,
          responseMetricData?.find((metric) => {
            return metric.metricType === metricType;
          })
        )
      );
    });

    this._store = {
      ...this._store,
      responseMetrics: newResponseMetrics,
    };
    this._store$.next(this._store);
  }

  getResponseMetrics$(metricType: UserResponseMetricType): Observable<DataStatus<UserResponseMetric>> {
    return this._store$.pipe(
      map((store) => {
        return store.responseMetrics.get(metricType);
      })
    );
  }

  getResponseMetricPresence$(metrics: UserResponseMetricType[]): Observable<boolean> {
    return this._store$.pipe(
      map((store) => {
        return metrics
          .map((metric) => store.responseMetrics.get(metric))
          .some((value) => value?.data?.metrics?.length > 0);
      })
    );
  }

  get insights$(): Observable<DataStatus<Insight[]>> {
    return this._store$.pipe(map((store) => store.insightsStatus));
  }

  setInsights(insightsStatus: DataStatus<Insight[]>) {
    this._store = {
      ...this._store,
      insightsStatus,
    };
    this._store$.next(this._store);
  }

  get reports$(): Observable<DataStatus<Report[]>> {
    return this._store$.pipe(map((store) => store.reportsStatus));
  }

  setReports(reportsStatus: DataStatus<Report[]>) {
    const organizations = { ...this._store.organizations };
    if (reportsStatus?.data != null && reportsStatus.data.length > 0) {
      const organizationToUpdate = new Organization().deserialize({
        ...this._store.organizations[this.selectedFilters.selectedOrganizationId],
      });
      organizationToUpdate.addReports(reportsStatus?.data);
      organizations[this.selectedFilters.selectedOrganizationId] = organizationToUpdate;
    }
    this._store = {
      ...this._store,
      organizations,
      reportsStatus,
    };
    this._store$.next(this._store);
  }

  get groups$(): Observable<Group[]> {
    return this._store$.pipe(map((store) => (Object.values(this._store.groups || {}) as Group[]) || []));
  }

  get groups(): Group[] {
    return Object.values(this._store$.value.groups || {}) as Group[];
  }

  groupsByOrg$(orgId: string): Observable<Group[]> {
    return this._store$.pipe(
      map((store: InsightfulStore) => {
        const selectedOrg = store.organizations[orgId] as Organization;
        return selectedOrg?.groups || [];
      })
    );
  }

  hasAccess(orgId: string): boolean {
    return orgId === OgSelector.all || (this._store.organizations[orgId] as Organization)?.hasAccess;
  }

  groupHasAccess(grpId: string): boolean {
    const groupOrgId = this.organizations.find((org) => org.groups.some((grp) => grp.id === grpId))?.id;
    return this.hasAccess(groupOrgId);
  }

  setGroups(groups: Organization[] | Group[]) {
    let _groups: OGMap = {};
    if (Array.isArray(groups?.[0]?.['groups'])) {
      // is an org, parse accordingly
      _groups = this._getGroupsFromOrganizations(groups as Organization[]);
    } else {
      _groups = (groups as Group[]).reduce((groupAccumulator, group) => {
        if (group) {
          groupAccumulator[group.id] = new Group().deserialize(group);
        }
        return groupAccumulator;
      }, {});
    }
    this._store = {
      ...this._store,
      groups: { ...this._store.groups, ..._groups },
    };
    this._store$.next(this._store);
    this._setFiltersForSingleOrgOrGroup(_groups, this.selectedFilters.selectedGroupId, OgSelector.group);
  }

  private _getGroupsFromOrganizations(organizations: Organization[]) {
    const organizationGroups = organizations
      .map((organization) => (organization ? organization.groups : []))
      .filter((ogs) => ogs);
    return (
      organizationGroups.length > 0 &&
      organizationGroups
        .reduce((allGroups, orgGroups) => [...allGroups, ...orgGroups])
        .reduce((groupsById, group) => {
          groupsById[group.id] = new Group().deserialize(group);
          return groupsById;
        }, {})
    );
  }

  get organizations$(): Observable<Organization[]> {
    return this._store$.pipe(map((store) => (Object.values(store.organizations || {}) as Organization[]) || []));
  }

  get organizations(): Organization[] {
    return Object.values(this._store$.value.organizations || {}) as Organization[];
  }

  setOrganizations(organizations: Organization[]) {
    const _organizations = organizations.reduce((orgAccumulator, org) => {
      if (org) {
        orgAccumulator[org.id] = new Organization().deserialize(org, this._store.organizations[org.id] as Organization);
      }
      return orgAccumulator;
    }, {});
    const _groups = this._getGroupsFromOrganizations(organizations);
    this._store = {
      ...this._store,
      organizations: { ...this._store.organizations, ..._organizations },
      groups: { ...this._store.groups, ..._groups },
    };
    this._store$.next(this._store);
    this._setFiltersForSingleOrgOrGroup(
      _organizations,
      this.selectedFilters.selectedOrganizationId,
      OgSelector.organization
    );
    this._setFiltersForSingleOrgOrGroup(_groups, this.selectedFilters.selectedGroupId, OgSelector.group);
  }

  get cards$(): Observable<CardTitle[]> {
    return this._store$.pipe(map((store) => store.cards));
  }

  get cards(): CardTitle[] {
    return this._store$.value.cards;
  }

  setCards(data: any, status: Status) {
    let cards: CardTitle[];
    if (data && Array.isArray(data)) {
      cards = data.map((card) => new CardTitle().deserialize(card));
    } else {
      cards = [new CardTitle().deserialize(data)];
    }
    this._store = {
      ...this._store,
      cards: status === Status.done ? cards : [],
    };
    this._store$.next(this._store);
  }

  get cardDynamicLinks$(): Observable<Record<string, string>> {
    return this._store$.pipe(map((store) => store.cardDynamicLinks));
  }

  get cardDynamicLinks(): Record<string, string> {
    return this._store$.value.cardDynamicLinks;
  }

  addCardDynamicLink(card: CardTitle, link: string) {
    const newLinks = { ...this._store.cardDynamicLinks, [card.id]: link };
    this._store = {
      ...this._store,
      cardDynamicLinks: newLinks,
    };
    this._store$.next(this._store);
  }
}

/**
 * Copies map entries into a new Map
 *
 * NOTE: The entries will be shallow copies, but the Map itself will be new
 */
function copyMapEntries<KeyType = any, ValueType = any>(oldMap: Map<KeyType, ValueType>) {
  const newMap = new Map<KeyType, ValueType>();
  const iterator = oldMap.entries();

  for (const entry of iterator) {
    const [key, value] = entry;
    // TODO: make a deep copy of value rather than using a shallow copy (and update fn description)
    newMap.set(key, value);
  }

  return newMap;
}
