import { Injectable } from '@angular/core';
import { GroupApi, LegalDocumentApi, OGApi } from '@kapi';
import { HttpStatusCode } from '@ktypes/enums';
import {
  DataStatus,
  Group,
  LegalDocument,
  LegalDocumentType,
  Organization,
  Status,
  StatusMessage,
} from '@ktypes/models';
import { BehaviorSubject, Observable, distinctUntilChanged } from 'rxjs';
import { map } from 'rxjs/operators';

interface LegalDocumentsData {
  privacy: LegalDocument;
  eula: LegalDocument;
}

enum PromiseSettledStatus {
  fulfilled = 'fulfilled',
  rejected = 'rejected',
}

@Injectable({
  providedIn: 'root',
})
export class OGBloc {
  constructor(
    private _ogApi: OGApi,
    private _groupApi: GroupApi,
    private _legalDocumentApi: LegalDocumentApi
  ) {}

  private _currentOrg$ = new BehaviorSubject<Organization>(null);
  private _currentGroup$ = new BehaviorSubject<Group>(null);
  private _allOrgs$ = new BehaviorSubject<Organization[]>(null);
  private _legalDocsStatus$ = new BehaviorSubject<DataStatus<LegalDocumentsData>>(null);

  currentOrg$: Observable<Organization> = this._currentOrg$.asObservable();
  currentGroup$: Observable<Group> = this._currentGroup$.asObservable();
  allOrgs$: Observable<Organization[]> = this._allOrgs$.asObservable();
  legalDocs$: Observable<LegalDocumentsData> = this._legalDocsStatus$.pipe(
    distinctUntilChanged(),
    map((legalDocsStatus) => legalDocsStatus?.status === Status.done && legalDocsStatus?.data)
  );

  requestAllOrgs(): void {
    this._ogApi.getOrganizationsAndGroups().then((orgsStatus) => {
      if (orgsStatus?.status === Status.done && orgsStatus?.data != null) {
        this._allOrgs$.next(orgsStatus.data.map((org) => new Organization().deserialize(org)));
      } else {
        this._allOrgs$.next(null);
      }
    });
  }

  getGroupByCode(groupCode: string) {
    this._groupApi.getGroupByCode(groupCode).then((groupStatus) => {
      if (groupStatus instanceof DataStatus && groupStatus?.status === Status.done) {
        this._currentGroup$.next(groupStatus.data);
      } else {
        this._currentGroup$.next(null);
      }
    });
  }

  getGroupById(groupId: string) {
    this._groupApi.getGroupById(groupId).then((groupStatus) => {
      if (groupStatus instanceof DataStatus && groupStatus?.status === Status.done) {
        this._currentGroup$.next(groupStatus.data);
      } else {
        this._currentGroup$.next(null);
      }
    });
  }

  getGroupLegalDocuments(_docType?: LegalDocumentType): void;
  getGroupLegalDocuments(_groupId?: string, _docType?: LegalDocumentType): void;
  getGroupLegalDocuments(groupIdOrDocType?: string | LegalDocumentType, _docType?: LegalDocumentType) {
    const groupInfo = this._currentGroup$.getValue();
    const groupCode = groupInfo?.groupCode;
    let groupId = typeof groupIdOrDocType === 'string' ? groupIdOrDocType : groupInfo?.id;
    const docType = typeof groupIdOrDocType !== 'string' ? groupIdOrDocType : _docType;

    if (!groupId && !groupCode && !groupInfo) {
      this._legalDocsStatus$.next(null);
      return;
    }

    if (this._legalDocsStatus$.getValue()?.status === Status.starting) {
      return;
    }

    this._legalDocsStatus$.next(new DataStatus(Status.starting, null, null));

    if (!groupId && groupCode) {
      this._groupApi.getGroupByCode(groupCode).then((groupCodeStatus) => {
        if (
          groupCodeStatus instanceof DataStatus &&
          groupCodeStatus?.status === Status.done &&
          groupCodeStatus?.data != null
        ) {
          groupId = groupCodeStatus.data.id;
        }
        this._getLegalDocsWithGroupId(groupId, docType);
      });
    } else {
      this._getLegalDocsWithGroupId(groupId, docType);
    }
  }

  private _getLegalDocsWithGroupId(groupId: string, docType: LegalDocumentType) {
    if (groupId) {
      switch (docType) {
        case LegalDocumentType.PRIVACY:
          this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY).then((privacyStatus) => {
            this._legalDocsStatus$.next(
              new DataStatus<LegalDocumentsData>(
                privacyStatus.status,
                privacyStatus.message,
                privacyStatus.status === Status.done
                  ? {
                      privacy: privacyStatus?.data,
                      eula: this._legalDocsStatus$.getValue()?.data?.eula || null,
                    }
                  : null
              )
            );
          });
          break;
        case LegalDocumentType.EULA:
          this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA).then((eulaStatus) => {
            this._legalDocsStatus$.next(
              new DataStatus<LegalDocumentsData>(
                eulaStatus.status,
                eulaStatus.message,
                eulaStatus.status === Status.done
                  ? {
                      privacy: this._legalDocsStatus$.getValue()?.data?.privacy || null,
                      eula: eulaStatus?.data,
                    }
                  : null
              )
            );
          });
          break;
        default:
          Promise.allSettled([
            this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.PRIVACY),
            this._legalDocumentApi.getLegalDocument(groupId, LegalDocumentType.EULA),
          ]).then((promiseSettledResults) => {
            const [privacyResult, eulaResult] = promiseSettledResults.map(
              (settledResult) => settledResult?.status === PromiseSettledStatus.fulfilled && settledResult?.value
            );
            const status = [privacyResult, eulaResult].some((settledResult) => settledResult?.status === Status.done);
            const message = new StatusMessage(
              status ? HttpStatusCode.OK : HttpStatusCode.NOT_FOUND,
              status ? PromiseSettledStatus.fulfilled : PromiseSettledStatus.rejected
            );
            this._legalDocsStatus$.next(
              new DataStatus<LegalDocumentsData>(status ? Status.done : Status.error, message, {
                privacy: privacyResult?.data || null,
                eula: eulaResult?.data || null,
              })
            );
          });
          break;
      }
    } else {
      // no groupId
      this._legalDocsStatus$.next(
        new DataStatus(Status.error, new StatusMessage(Status.error, 'Error'), { privacy: null, eula: null })
      );
    }
  }
}
