import { DOCUMENT } from '@angular/common';
import { Component, EventEmitter, Inject, Input, OnInit, Output } from '@angular/core';
import { OgSelector } from '@ki/shared/components/og-selector/og-selector';
import { Option } from '@ki/shared/models/option';
import { FilterSelectorsBloc } from '@ki/shared/services/filter-selectors.bloc';
import { WINDOW } from '@kservice';
import { MockComponent } from '@kutil/test';

@Component({
  selector: 'ki-site-header',
  templateUrl: './site-header.component.html',
  styleUrls: ['./site-header.component.scss'],
})
export class SiteHeaderComponent implements OnInit {
  @Input() navActive: boolean;
  @Input() showEligibleDropDown = true;
  @Input() showGroupsDropdown = true;
  @Output() navBtnClick = new EventEmitter<void>();

  ogSelector = OgSelector;

  constructor(
    @Inject(DOCUMENT) private _document: Document,
    public filterSelectorsBloc: FilterSelectorsBloc,
    @Inject(WINDOW) private window: Window
  ) {}

  ngOnInit(): void {
    void this.filterSelectorsBloc.requestAllOrgs();
  }

  onNavBtnClick(e: MouseEvent) {
    if (this.navActive) {
      (this.window.document.activeElement as HTMLElement).blur();
    }
    this.navBtnClick.emit();
    e.preventDefault();
  }

  sideNavTab(e: Event) {
    if (this.navActive) {
      (this._document.querySelectorAll('.sidebar-nav .nav-item')?.[0] as HTMLElement).focus();
      e.preventDefault();
    }
  }

  ogNamesMatch(organizations: Option[], groups: Option[]): boolean {
    return organizations?.[0]?.name === groups?.[0]?.name;
  }
}

export const MockSiteHeaderComponent = MockComponent({
  selector: 'ki-site-header',
  inputs: ['navActive', 'showEligibleDropDown', 'showGroupsDropdown'],
});
