import { Document } from '@contentful/rich-text-types';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class AdminPulseSurvey implements Deserializable<JsonObject, AdminPulseSurvey> {
  constructor(
    public id?: string,
    public title?: string,
    public nonDisplayTitle?: string,
    public active?: boolean,
    public purposeful?: boolean,
    public resourceful?: boolean,
    public assessment?: boolean,
    public emailFrom?: string,
    public emailSubject?: string,
    public imageId?: string,
    public descriptionEmail?: Document,
    public descriptionApp?: Document,
    public callToAction?: string,
    public priority?: number,
    public startDaysAfter?: number,
    public accountCreation?: boolean,
    public frequencyInterval?: number,
    public canDeliverInApp?: boolean,
    public canEmailToAppUsers?: boolean,
    public minDeliveryTimestamp?: Date,
    public maxDeliveryTimestamp?: Date,
    public bulkSendTimestamp?: Date,
    public bulkCanEmailToAppUsers?: boolean,
    public bulkCanEmailToNonAppUsers?: boolean,
    public questionSetOrDialogueId?: string,
    public contentReportId?: string
  ) {}

  deserialize(json: JsonObject): AdminPulseSurvey {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.title = json['title'] as string;
    this.nonDisplayTitle = json['nonDisplayTitle'] as string;
    this.active = json['active'] as boolean;
    this.purposeful = json['purposeful'] as boolean;
    this.resourceful = json['resourceful'] as boolean;
    this.assessment = json['assessment'] as boolean;
    this.emailFrom = json['emailFrom'] as string;
    this.emailSubject = json['emailSubject'] as string;
    this.imageId = json['imageId'] as string;
    this.descriptionEmail = json['descriptionEmail'] as Document;
    this.descriptionApp = json['descriptionApp'] as Document;
    this.callToAction = json['callToAction'] as string;
    this.priority = json['priority'] as number;
    this.startDaysAfter = json['startDaysAfter'] as number;
    this.accountCreation = json['accountCreation'] as boolean;
    this.frequencyInterval = json['frequencyInterval'] as number;
    this.canDeliverInApp = json['canDeliverInApp'] as boolean;
    this.canEmailToAppUsers = json['canEmailToAppUsers'] as boolean;
    if (json['minDeliveryTimestamp']) {
      this.minDeliveryTimestamp = new Date(json['minDeliveryTimestamp']);
      this.active = this.active && new Date() >= this.minDeliveryTimestamp;
    }
    if (json['maxDeliveryTimestamp']) {
      this.maxDeliveryTimestamp = new Date(json['maxDeliveryTimestamp']);
      this.active = this.active && new Date() <= this.maxDeliveryTimestamp;
    }
    if (json['bulkSendTimestamp']) {
      this.bulkSendTimestamp = new Date(json['bulkSendTimestamp']);
    }
    this.bulkCanEmailToAppUsers = json['bulkCanEmailToAppUsers'] as boolean;
    this.bulkCanEmailToNonAppUsers = json['bulkCanEmailToNonAppUsers'] as boolean;
    this.questionSetOrDialogueId = json['questionSetOrDialogueId'] as string;
    this.contentReportId = json['contentReportId'] as string;

    return this;
  }
}
