import { UserResponseMetricGroupingType, UserResponseMetricType } from '../enums';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserResponseMetricGrouping implements Deserializable<JsonObject, UserResponseMetricGrouping> {
  constructor(
    public grouping?: UserResponseMetricGroupingType,
    public types?: UserResponseMetricType[],
    public title?: string,
    public filterName?: string
  ) {}

  deserialize(input: JsonObject): UserResponseMetricGrouping {
    if (input == null) {
      return null;
    }

    this.grouping = input['grouping'] as UserResponseMetricGroupingType;
    this.types = input['types'] as UserResponseMetricType[];
    this.title = input['title'] as string;
    this.filterName = input['filterName'] as string;
    return this;
  }
}
