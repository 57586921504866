import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class Icon implements Deserializable<JsonObject, Icon> {
  constructor(
    public image?: string,
    public altText?: string
  ) {}

  deserialize(input: JsonObject): Icon {
    if (input == null) {
      return null;
    }

    this.image = input['image'] as string;
    this.altText = input['altText'] as string;

    return this;
  }
}
