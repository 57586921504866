import { AnalyticsPageName, PageMappingData } from '@kanalytics';

export const pageMappingData: PageMappingData = {
  pages: [
    { pageName: AnalyticsPageName.dashboard, regex: /^\/dashboard.*/, meta: { focusElementSelector: '.title' } },
    { pageName: AnalyticsPageName.help, regex: /^\/help.*/ },
    {
      pageName: AnalyticsPageName.verifyMfaResendCode,
      regex: /^\/login\/email\/verify-mfa\/no-code.*/,
      meta: { focusTitle: "Didn't Receive a Code?" },
    },
    {
      pageName: AnalyticsPageName.verifyMfa,
      regex: /^\/login\/email\/verify-mfa.*/,
      meta: { focusTitle: "Verify It's You" },
    },
    { pageName: AnalyticsPageName.login, regex: /^\/login.*/ },
    { pageName: AnalyticsPageName.privacy, regex: /^\/privacy.*/ },
    { pageName: AnalyticsPageName.reports, regex: /^\/reports.*/, meta: { focusElementSelector: '.title' } },
  ],
  dontTrack: ['/login/email', '/login/reset/code', '/login/reset/password', '/logout'],
  dontCache: ['/login'],
};
