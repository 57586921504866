import { Injectable } from '@angular/core';
import { UserApi, UserEventApi } from '@kapi';
import { AuthenticationApi, BaseUserBloc } from '@kauth';
import { AuthDataService, DataStoreService } from '@kservice';

@Injectable({
  providedIn: 'root',
})
export class UserBloc extends BaseUserBloc {
  constructor(
    authData: AuthDataService,
    authApi: AuthenticationApi,
    dataStore: DataStoreService,
    userApi: UserApi,
    userEventsApi: UserEventApi
  ) {
    super(authData, authApi, dataStore, userApi, userEventsApi);
  }
}
