<header class="top-header" [ngClass]="{ active: navActive }">
  <button
    class="top-nav-item nav-menu-button"
    [ngClass]="{ active: navActive }"
    (click)="onNavBtnClick($event)"
    aria-label="Side Navigation Toggle"
    (keydown.tab)="sideNavTab($event)">
    <span class="nav-menu-icon" aria-hidden="false" [attr.aria-label]="navActive ? 'Open Menu' : 'Close Menu'"></span>
  </button>
  <a href="/" class="top-nav-item logo">
    <svg-icon class="white-logo" src="/assets/logo/logo-insightful-by-kumanu-white.svg"></svg-icon>
  </a>
  <aside class="top-nav-item client-selectors">
    @if (filterSelectorsBloc.organizationOptions$ | async; as orgOptions) {
      @if (filterSelectorsBloc.groupOptions$ | async; as grpOptions) {
        @if (orgOptions.length > 0) {
          <ki-og-selector
            class="client-selector org-selector"
            [config]="{ color: 'transparent', leadIcon: '/assets/icons/icon_navigation_business.svg', dropIcon: true }"
            [options]="orgOptions"
            [type]="ogSelector.organization"
            semanticLabel="Organization"></ki-og-selector>
        }
        @if (
          showGroupsDropdown &&
          (grpOptions.length > 1 || (orgOptions.length > 0 && !ogNamesMatch(orgOptions, grpOptions)))
        ) {
          <ki-og-selector
            class="client-selector group-selector"
            [config]="{ color: 'transparent', leadIcon: '/assets/icons/icon_navigation_group.svg', dropIcon: false }"
            [options]="grpOptions"
            [type]="ogSelector.group"
            semanticLabel="Group"></ki-og-selector>
        }
      }
    }
    @if (showEligibleDropDown && (filterSelectorsBloc.eligibilitySelectorVisibility$ | async)) {
      <ki-eligibility-selector class="eligibility-selector"></ki-eligibility-selector>
    }
  </aside>
</header>
