import { Deserializable } from './deserializable.model';
import { Icon } from './icon.model';
import { JsonObject } from './json-object.model';

export class Insight implements Deserializable<JsonObject, Insight> {
  constructor(
    public title?: string,
    public insight?: string,
    public icon?: Icon
  ) {}

  deserialize(input: JsonObject, existingData?: Insight): Insight {
    if (input == null) {
      return null;
    }

    this.title = input['title'] as string;
    this.insight = input['insight'] as string;

    if (input['icon']) {
      this.icon = new Icon().deserialize(input['icon']);
    }

    return this;
  }
}
