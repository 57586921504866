import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AnalyticEvent, AnalyticsBloc, AnalyticsPageName } from '@kanalytics';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { InsightfulDataStoreService } from '@ki/core/data-store.service';
import { OgConfiguration, OgSelector } from '@ki/shared/components/og-selector/og-selector';
import { Option } from '@ki/shared/models/option';
import { FilterSelectorsBloc } from '@ki/shared/services/filter-selectors.bloc';
import { StringUtil } from '@kutil';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'ki-og-selector',
  templateUrl: './og-selector.component.html',
  styleUrls: ['./og-selector.component.scss'],
})
export class OgSelectorComponent implements OnInit, OnDestroy {
  @Input() config: OgConfiguration = {
    color: 'transparent',
    leadIcon: '/assets/icons/icon_navigation_business.svg',
    dropIcon: true,
  };
  @Input() options: Option[];
  @Input() semanticLabel: string;
  @Input() type: OgSelector;

  private _destroy$ = new Subject<void>();
  selectedOption: string;

  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authBloc: AuthenticationBloc,
    private _insightfulDataStoreService: InsightfulDataStoreService,
    private _filterSelectorsBloc: FilterSelectorsBloc,
    private _router: Router
  ) {}

  ngOnInit(): void {
    let savedOption;
    switch (this.type) {
      case OgSelector.group:
        savedOption = this._insightfulDataStoreService.selectedFilters.selectedGroupId;
        break;
      case OgSelector.organization:
        savedOption = this._insightfulDataStoreService.selectedFilters.selectedOrganizationId;
        break;
      case OgSelector.all:
      default:
        savedOption = 'all';
        break;
    }
    if (!this._filterSelectorsBloc.updatedFromStorage) {
      this.optionsChanged(savedOption, false);
    }

    // Subscribe to filter changes
    this._insightfulDataStoreService.selectedFilters$.pipe(takeUntil(this._destroy$)).subscribe((selectedFilters) => {
      switch (this.type) {
        case OgSelector.organization:
          if (selectedFilters.selectedOrganizationId !== this.selectedOption) {
            this.selectedOption = selectedFilters.selectedOrganizationId;
          }
          break;
        case OgSelector.group:
          if (selectedFilters.selectedGroupId !== this.selectedOption) {
            this.selectedOption = selectedFilters.selectedGroupId;
          }
          break;
        default:
          break;
      }
    });
  }

  ngOnDestroy() {
    this._destroy$.next();
    this._destroy$.complete();
  }

  optionsChanged(id: string, shouldTrack = true): void {
    this.selectedOption = id;
    const nonChangedOrgGroupSwitchMap = {
      [OgSelector.organization]: OgSelector.group,
      [OgSelector.group]: OgSelector.organization,
    };
    const nonChangedOGId = nonChangedOrgGroupSwitchMap[this.type];
    if (shouldTrack) {
      this._analyticsBloc.sendEvent(
        this._analyticsBloc.createEventFromInteraction({
          page: this._router.url as AnalyticsPageName,
          event: AnalyticEvent[`change_${this.type}_selector`],
          meta: {
            [`selected${StringUtil.capitalizeFirstLetter(this.type)}Id`]: id,
            [`selected${nonChangedOGId}Id`]:
              this._insightfulDataStoreService.selectedFilters[`selected${nonChangedOGId}Id`],
            selectedTimeframe: this._insightfulDataStoreService.selectedFilters.selectedFilterTimeframe,
          },
        })
      );
    }
    this._filterSelectorsBloc.updateFilter(id, this.type);
  }
}
