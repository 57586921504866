import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class UserResponseMetricTooltip implements Deserializable<JsonObject, UserResponseMetricTooltip> {
  constructor(
    public label?: string,
    public link?: string
  ) {}

  deserialize(input: JsonObject): UserResponseMetricTooltip {
    if (input == null) {
      return null;
    }

    this.label = input['label'] as string;
    this.link = input['link'] as string;

    return this;
  }
}
