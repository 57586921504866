import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AnalyticsBloc } from '@kanalytics';
import { HeartbeatApi } from '@kapi';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class NotAuthenticatedGuard {
  constructor(
    private _analyticsBloc: AnalyticsBloc,
    private _authenticationBloc: AuthenticationBloc,
    private _heartbeatApi: HeartbeatApi,
    private _router: Router
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    // no need to check isTokenExpiredLocally() as the heartbeat call will try to auto-refresh the token if expired
    return this._heartbeatApi.isValid$(true).pipe(
      map((isValid) => {
        if (isValid) {
          // side effect, if valid, means auth is good, can allow analytics to begin
          this._analyticsBloc.userReadyForAnalytics();
          return this._router.parseUrl('/dashboard');
        }
        this._authenticationBloc.logout(false);
        return this._router.parseUrl('/login');
      }),
      catchError((err) => {
        console.warn(err);
        this._authenticationBloc.logout(false);
        void this._router.navigate(['/login'], {
          queryParams: {
            redirectUrl: encodeURIComponent(state.url),
          },
        });
        return of(false);
      })
    );
  }
}
