import { AfterViewInit, Component } from '@angular/core';
import { environment } from '@ki/../environments/environment';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { Constants } from '@ki/shared/constants.service';
import { Popup } from '@kutil';
import get from 'lodash/get';

@Component({
  selector: 'ki-screen-logout',
  templateUrl: './screen-logout.component.html',
  styleUrls: ['./screen-logout.component.scss'],
})
export class ScreenLogoutComponent implements AfterViewInit {
  popup: Popup;
  logoutCalled = false;

  constructor(
    public authenticationBloc: AuthenticationBloc,
    private _popupService: Popup
  ) {}

  ngAfterViewInit() {
    this.popup = this._popupService.initialize({
      url: 'about:blank',
      name: 'ssologout',
      windowFeatures: {
        width: 640,
        height: 680,
      },
      onClose: this.onPopupClose.bind(this),
    });

    setTimeout(() => {
      // Make sure the user is logged out
      if (this.popup) {
        const url = Constants.ssoLogoutUrlTemplate
          .replace('$authHost', environment.oauth.domain)
          .replace('$clientId', environment.oauth.authClientSocialId)
          .replace('$kumanuLogout', encodeURIComponent(environment.oauth.redirectSignOut));

        // Make sure the user is logged out
        this.popup.change_url(url);

        // check for url change and close popup
        this.checkPopupUrl();

        // force logout after 4 seconds regardless
        setTimeout(() => {
          if (this.popup) {
            this.popup.close();
          }
          if (!this.logoutCalled) {
            this.authenticationBloc.logout();
            this.logoutCalled = true;
          }
        }, 4000);
      }
    });
  }

  private checkPopupUrl() {
    if (get(this.popup, 'popupWin.location.href', '').includes(environment.oauth.redirectSignOut)) {
      this.popup.close();
      if (!this.logoutCalled) {
        this.authenticationBloc.logout();
        this.logoutCalled = true;
      }
      return;
    }

    // check for url change and close popup
    setTimeout(() => {
      this.checkPopupUrl();
    }, 250);
  }

  private onPopupClose() {
    if (this && !this.logoutCalled) {
      this.authenticationBloc?.logout();
      this.logoutCalled = true;
    }
  }
}
