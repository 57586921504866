@if (navActive) {
  <div class="overlay" role="presentation" (click)="closeOverlay()" (keyup.escape)="closeOverlay()"></div>
}

<kui-a11y-live-announce politenessSetting="assertive" [announcement]="sideMenuAnnouncement"></kui-a11y-live-announce>
<aside
  class="sidebar-nav"
  [ngClass]="{ active: navActive }"
  [attr.aria-hidden]="navActive ? null : 'true'"
  [attr.tabindex]="navActive ? null : -1"
  (click)="onSidebarClick($event)"
  (keydown.enter)="onSidebarClick($event)">
  <nav class="nav-items" [attr.aria-hidden]="navActive ? null : 'true'" [attr.tabindex]="navActive ? null : -1">
    @for (navItem of navBloc.sideNavSubject$ | async; track navItem; let first = $first) {
      @if (!navItem.external) {
        <a
          [routerLink]="navItem.link"
          class="nav-item"
          [attr.aria-hidden]="navActive ? null : 'true'"
          [attr.tabindex]="navActive ? null : -1"
          (keydown.shift.tab)="first ? sideNavTab($event) : true"
          routerLinkActive="nav-active"
          ariaCurrentWhenActive="page">
          <span class="nav-item-inner">
            <svg-icon [src]="navItem.icon" class="navIcon"></svg-icon>
            <span class="nav-item-text">{{ navItem.title }}</span>
          </span>
        </a>
      }
      @if (navItem.external) {
        <a
          [href]="navItem.link"
          class="nav-item"
          (click)="closeOverlay()"
          target="_blank"
          [attr.aria-label]="navItem.title + ' - External Link'"
          [attr.aria-hidden]="navActive ? null : 'true'"
          [attr.tabindex]="navActive ? null : -1"
          (keydown.shift.tab)="first ? sideNavTab($event) : true"
          routerLinkActive="nav-active"
          ariaCurrentWhenActive="page">
          <span class="nav-item-inner">
            <svg-icon [src]="navItem.icon" class="navIcon"></svg-icon>
            <span class="nav-item-text">{{ navItem.title }}</span>
            <svg-icon src="/assets/shared/images/icons/expand.svg" class="external-link-icon"></svg-icon>
          </span>
        </a>
      }
    }

    @if (navBloc.moreApps$ | async; as apps) {
      @if (apps.length > 1) {
        <div class="more-apps">
          <span class="more-apps-title">More by Kumanu</span>
        </div>
        @for (app of apps; track app) {
          @if (!app?.currentApp) {
            <a
              [href]="app?.link"
              class="nav-item"
              [attr.aria-label]="app.title + ' - External Link'"
              [attr.aria-hidden]="navActive ? null : 'true'"
              [attr.tabindex]="navActive ? null : -1"
              (click)="trackEvent(app)"
              target="_blank">
              <span class="nav-item-inner">
                <svg-icon [src]="app?.whiteIcon" class="nav-icon"></svg-icon>
                <span class="nav-item-text">{{ app?.title }}</span>
                <svg-icon src="/assets/shared/images/icons/expand.svg" class="external-link-icon"></svg-icon>
              </span>
            </a>
          }
        }
      }
    }
  </nav>
  <nav class="tertiary-nav">
    <a
      routerLink="/logout"
      class="tertiary-nav-item"
      (keydown.tab)="closeOverlay()"
      [attr.aria-hidden]="navActive ? null : 'true'"
      [attr.tabindex]="navActive ? null : -1"
      routerLinkActive="nav-active"
      ariaCurrentWhenActive="page">
      <span class="nav-item-inner">
        <svg-icon src="/assets/icons/icon_navigation_signout.svg" class="nav-icon tertiary-nav-icon"></svg-icon>
        <span class="nav-item-text">Sign Out</span>
      </span>
    </a>
  </nav>
</aside>
