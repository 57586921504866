import { Document } from '@contentful/rich-text-types';
import { ContentMedia } from './content-media.model';
import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { SocialChallengeState } from './social-challenge.model';

export class AdminSocialChallenge implements Deserializable<JsonObject, AdminSocialChallenge> {
  constructor(
    public id?: string,
    public contentId?: string,
    public title?: string,
    public domainKey?: string,
    public mediaObject?: ContentMedia,
    public description?: Document,
    public objective?: string,
    public instruction?: Document,
    public groupGoal?: number,
    public groupMotivation?: Document,
    public groupCompletionMessage?: Document,
    public individualGoal?: number,
    public individualMotivation?: Document,
    public individualCompletionMessage?: Document,
    public inviteMessage?: string,
    public autoEnroll?: boolean,
    public startDate?: Date,
    public endDate?: Date,
    public status?: SocialChallengeState
  ) {}

  deserialize(json: JsonObject): AdminSocialChallenge {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.contentId = json['contentId'] as string;
    this.title = json['title'] as string;
    this.domainKey = json['domainKey'] as string;
    this.mediaObject = new ContentMedia().deserialize(json['mediaObject']);
    this.description = json['description'] as Document;
    this.objective = json['objective'] as string;
    this.instruction = json['instruction'] as Document;
    this.groupGoal = json['groupGoal'] as number;
    this.groupMotivation = json['groupMotivation'] as Document;
    this.groupCompletionMessage = json['groupCompletionMessage'] as Document;
    this.individualGoal = json['individualGoal'] as number;
    this.individualMotivation = json['individualMotivation'] as Document;
    this.individualCompletionMessage = json['individualCompletionMessage'] as Document;
    this.inviteMessage = json['inviteMessage'] as string;
    this.autoEnroll = json['autoEnroll'] as boolean;
    this.startDate = new Date(json['startDate']);
    this.endDate = new Date(json['endDate']);
    this.status = (json['status'] || {}) as SocialChallengeState;

    return this;
  }

  get image() {
    return this.mediaObject?.asImage;
  }
}
