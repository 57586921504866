import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class AdminTranslation implements Deserializable<JsonObject, AdminTranslation> {
  constructor(
    public id?: string,
    public source?: string,
    public translation?: string,
    public updateDate?: Date
  ) {}

  deserialize(json: JsonObject): AdminTranslation {
    if (json == null) {
      return null;
    }

    this.id = json['id'] as string;
    this.source = json['source'] as string;
    this.translation = json['translation'] as string;
    if (json['updateDate']) {
      this.updateDate = new Date(json['updateDate'] as string);
    }

    return this;
  }
}
