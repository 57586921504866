export enum ErrorState {
  allOrganizations,
  denied,
  generic,
  noOrganization,
  noReports,
  notEnoughData,
  notFound,
}

// TODO: Use error types for better error validation
export type PasswordErrorState = 'PasswordResetRequiredException' | 'InvalidPasswordException';
export type CodeErrorState = 'CodeMismatchException' | 'ExpiredCodeException' | 'CodeDeliveryFailureException';
export type UserCreationErrorState = 'LimitExceededException' | 'UsernameExistsException' | 'AliasExistsException';
export type AuthErrorState = PasswordErrorState | CodeErrorState | UserCreationErrorState;
