import { Injectable } from '@angular/core';
import { Route, Router, UrlTree } from '@angular/router';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { UserBloc } from '@ki/user/user.bloc';
import { DataStoreService } from '@kservice';
import { Observable, combineLatest } from 'rxjs';
import { filter, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class FeatureGuard {
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _dataStoreService: DataStoreService,
    private _router: Router,
    private _userBloc: UserBloc
  ) {}

  canMatch(route: Route /* , segments: UrlSegment[] */): boolean | Observable<boolean | UrlTree> {
    // If there are no required features, allow the navigation
    if (route?.data?.requiredFeatures == null || route.data.requiredFeatures.length === 0) {
      return true;
    }
    return combineLatest([this._userBloc.fetchingUser$, this._dataStoreService.user$]).pipe(
      filter(
        ([fetchingUser, user]) => !fetchingUser?.data && ((!user || user?.hasLoadedData || user?.hasNoData) as boolean)
      ),
      map(([_, user]) => {
        // TODO: should there be "requiredFeatures" (all required) and "matchAnyFeatures" (match at least one)?
        // currently only need to match 1 required feature, not all, to be a match
        const featureMatched = route.data?.requiredFeatures?.some?.((requiredFeature: string) =>
          user?.features?.map((feature) => feature?.key?.toLowerCase())?.includes(requiredFeature.toLowerCase())
        );
        if (!featureMatched) {
          this._authenticationBloc.logout(false);
        }
        // defaults to blocking navigation, redirect to error page
        return featureMatched || this._router.parseUrl('/error/denied');
      })
    );
  }
}
