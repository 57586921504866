import { Inject, Injectable } from '@angular/core';
import { AuthenticationApi, BaseAuthenticationBloc } from '@kauth';
import { AuthDataService, BrowserStorage, DataStoreService, OnboardingUtilities, WINDOW } from '@kservice';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationBloc extends BaseAuthenticationBloc {
  constructor(
    _authDataService: AuthDataService,
    _authenticationApi: AuthenticationApi,
    public browserStorage: BrowserStorage,
    _dataStoreService: DataStoreService,
    public onboardingUtilities: OnboardingUtilities,
    @Inject(WINDOW) _window: Window
  ) {
    super(_authDataService, _authenticationApi, browserStorage, _dataStoreService, onboardingUtilities, _window);
  }

  clearAuthData() {
    this.browserStorage.remove('selectedFilters');
    super.clearAuthData();
  }

  async changePassword(passwordCode: string, newPassword: string): Promise<void> {
    super.changePassword(passwordCode, newPassword);
  }
}
