import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ScreenLogoutComponent } from '@ki/screen-logout/screen-logout.component';
import { BarCollectionComponent } from '@ki/shared/components/bar-collection/bar-collection.component';
import { DropdownComponent } from '@ki/shared/components/dropdown/dropdown.component';
import { EligibilitySelectorComponent } from '@ki/shared/components/eligibility-selector/eligibility-selector.component';
import { ErrorComponent } from '@ki/shared/components/error/error.component';
import { FormatForWordCloudComponent } from '@ki/shared/components/format-for-word-cloud/format-for-word-cloud.component';
import { OgSelectorComponent } from '@ki/shared/components/og-selector/og-selector.component';
import { ProgressBarComponent } from '@ki/shared/components/progress-bar/progress-bar.component';
import { ShimmerComponent } from '@ki/shared/components/shimmer/shimmer.component';
import { SiteHeaderComponent } from '@ki/shared/components/site-header/site-header.component';
import { SiteNavComponent } from '@ki/shared/components/site-nav/site-nav.component';
import { StatisticBarComponent } from '@ki/shared/components/statistic-bar/statistic-bar.component';
import { UserResponseMetricSelectorComponent } from '@ki/shared/components/user-response-metric-selector/user-response-metric-selector.component';
import { WidgetHeaderComponent } from '@ki/shared/components/widget-header/widget-header.component';
import { WordCloudComponent } from '@ki/shared/components/word-cloud/word-cloud.component';
import { Constants } from '@ki/shared/constants.service';
import { BrowserStorage } from '@kservice';
import { A11yLiveAnnounceComponent, UiModule } from '@kui';
import { Popup, UtilsModule } from '@kutil';
import { TippyDirective } from '@ngneat/helipopper';
import { AngularSvgIconModule, SvgIconComponent } from 'angular-svg-icon';

@NgModule({
  declarations: [
    BarCollectionComponent,
    DropdownComponent,
    EligibilitySelectorComponent,
    ErrorComponent,
    FormatForWordCloudComponent,
    OgSelectorComponent,
    ProgressBarComponent,
    ScreenLogoutComponent,
    ShimmerComponent,
    SiteHeaderComponent,
    SiteNavComponent,
    StatisticBarComponent,
    WidgetHeaderComponent,
    UserResponseMetricSelectorComponent,
    WordCloudComponent,
  ],
  imports: [
    A11yLiveAnnounceComponent,
    AngularSvgIconModule.forRoot(),
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    RouterModule,
    TippyDirective,
    UiModule,
    UtilsModule,
    A11yLiveAnnounceComponent,
  ],
  exports: [
    A11yLiveAnnounceComponent,
    AngularSvgIconModule,
    BarCollectionComponent,
    DropdownComponent,
    ErrorComponent,
    FormatForWordCloudComponent,
    HttpClientModule,
    ProgressBarComponent,
    ShimmerComponent,
    SiteHeaderComponent,
    SiteNavComponent,
    SvgIconComponent,
    TippyDirective,
    UserResponseMetricSelectorComponent,
    WidgetHeaderComponent,
    WordCloudComponent,
    UiModule,
  ],
  providers: [BrowserStorage, Constants, Popup],
})
export class SharedModule {}
