import { Injectable } from '@angular/core';
import { environment } from '@ki/../environments/environment';
import { captureException, init } from '@sentry/angular-ivy';

init({
  dsn: 'https://6795e8a0deb2411f9a25ec5aacde655a@sentry.io/4937826',
  environment: environment.environment,
});

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler {
  handleError(error) {
    if (environment.production) {
      captureException((error && error.originalError) || error || new Error('Error was null or undefined'));
    } else {
      console.error(error);
    }
  }
}
