import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

interface MetricTertiary {
  target: number;
  polarity: number | boolean | string;
}

export class Metric implements Deserializable<JsonObject, Metric> {
  constructor(
    public title?: string,
    public primaryTooltipText?: string,
    public primary?: number,
    public secondaryText?: string,
    public secondary?: number,
    public secondaryTooltipText?: string,
    public tertiary?: MetricTertiary,
    public tertiaryText?: string,
    public candidateCount?: number
  ) {}

  deserialize(this: Metric, json: JsonObject): Metric {
    if (json == null) {
      return null;
    }
    this.title = json['title'] as string;
    this.primaryTooltipText = json['primaryTooltipText'] as string;
    this.primary = parseInt(json['primary'], 10);
    this.secondaryText = json['secondaryText'] as string;
    this.secondary = parseInt(json['secondary'], 10);
    this.secondaryTooltipText = json['secondaryTooltipText'] as string;
    this.tertiary = json['tertiary'] as MetricTertiary;
    this.tertiaryText = json['tertiaryText'] as string;
    this.candidateCount = json['candidateCount'] as number;
    return this;
  }
}
