import { Injectable } from '@angular/core';
import { AuthenticationBloc } from '@ki/auth/authentication.bloc';
import { InsightfulDataStoreService, SelectedFilter } from '@ki/core/data-store.service';
import { FilterSelectorsBloc } from '@ki/shared/services/filter-selectors.bloc';
import { BrowserStorage, DataStoreService } from '@kservice';

@Injectable({
  providedIn: 'root',
})
export class AppBloc {
  constructor(
    private _authenticationBloc: AuthenticationBloc,
    private _browserStorage: BrowserStorage,
    private _dataStoreService: DataStoreService,
    private _filterSelectorsBloc: FilterSelectorsBloc,
    private _insightfulDataStore: InsightfulDataStoreService
  ) {}

  loadStoredData() {
    const selectedFilters = this._browserStorage.getObject('selectedFilters') as SelectedFilter;
    if (selectedFilters) {
      if (this._dataStoreService.user?.id === selectedFilters.userId) {
        this._filterSelectorsBloc.updatedFromStorage = true;
        this._insightfulDataStore.setSelectedFilter(selectedFilters);
      } else if (this._authenticationBloc.isLoggedIn()) {
        this._browserStorage.remove('selectedFilters');
      }
    }
  }
}
