import { Role } from '@ktypes/enums';
import { MapCategory, NavItem, NavItemType } from '@ktypes/models';

export class Constants {
  static dateFormatForLocal = "yyyy-MM-dd'T'HH:mm:ss.SSSXXX";
  static ssoLoginUrlTemplate = `$authHost/oauth2/authorize?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&prompt=select_account&state=$state&scope=email%20openid%20phone%20profile`;
  static ssoLogoutUrlTemplate = '$authHost/logout?client_id=$clientId&logout_uri=$kumanuLogout';
  static ssoLogoutAndReloginUrlTemplate =
    '$authHost/logout?identity_provider=$provider&redirect_uri=$kumanuHost&response_type=$response_type&client_id=$clientId&prompt=select_account&state=$state&scope=email%20openid%20phone%20profile';

  static supportedBrowsers = [
    {
      displayName: 'Edge',
      name: 'MS-Edge-Chromium',
      version: 80,
      os: ['Windows', 'Mac'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Edge',
      name: 'MS-Edge',
      version: 80,
      os: ['Android', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/edge.png',
      url: 'https://www.microsoft.com/en-us/edge/',
    },
    {
      displayName: 'Chrome',
      name: 'Chrome',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/chrome.png',
      url: 'https://www.google.com/chrome/',
    },
    {
      displayName: 'Firefox',
      name: 'Firefox',
      version: 0,
      os: [],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/firefox.png',
      url: 'https://www.mozilla.org/en-US/firefox/new/',
    },
    {
      displayName: 'Safari',
      name: 'Safari',
      version: 12,
      os: ['Mac', 'iOS'],
      matchExact: false,
      recommend: true,
      icon: '/assets/icons/browsers/safari.png',
      url: 'https://support.apple.com/downloads/safari',
    },
  ];

  static navItemList: NavItem[] = [
    {
      link: '/dashboard',
      icon: '/assets/icons/icon_navigation_dashboard.svg',
      title: 'Dashboard',
      displayOrder: { insightful: { side: 10 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.standard,
    },
    {
      link: '/reports',
      icon: '/assets/icons/icon_navigation_reports.svg',
      title: 'Reports',
      displayOrder: { insightful: { side: 20 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.standard,
    },
    {
      link: '/admin',
      icon: '/assets/icons/admin-badge.svg',
      title: 'Admin',
      displayOrder: { insightful: { side: 30 } },
      mapCategory: MapCategory.main,
      external: false,
      type: NavItemType.elevatedAccess,
      roles: [Role.INSIGHTFUL_ADMIN],
    },
    {
      link: '/site-map',
      icon: '/assets/icons/globe.svg',
      title: 'Site Map',
      displayOrder: { insightful: { side: 40 } },
      mapCategory: MapCategory.main,
      external: false,
      hideFromMap: true,
      type: NavItemType.standard,
    },
  ];
}

export enum OauthResponseTypes {
  code = 'code',
  token = 'token',
}
