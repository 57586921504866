import { FilterTime } from '../enums/filter-time.enum';

export class FilterTimeData {
  private static _FilterWordsMap = {
    PRIOR7: { display: 'Prior 7 days', dropdown: 'Past 7 days', days: 7 },
    PRIOR30: { display: 'Prior 30 days', dropdown: 'Past 30 days', days: 30 },
    ALL_TIME: { display: 'All time', dropdown: 'All time', days: -1 },
  };

  static getDisplay(filterType: FilterTime) {
    return this._FilterWordsMap[filterType].display;
  }

  static getDropdown(filterType: FilterTime) {
    return this._FilterWordsMap[filterType].dropdown;
  }

  static getDays(filterType: FilterTime) {
    return this._FilterWordsMap[filterType].days;
  }
}
