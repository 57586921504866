@if (options?.length > 1) {
  <ki-dropdown
    [selectedOption]="selectedOption"
    [config]="config"
    [options]="options"
    [semanticLabel]="semanticLabel"
    (optionChanged)="optionsChanged($event)"></ki-dropdown>
}
@if (options?.length === 1) {
  <div class="selectBordered {{ config.color }}" title="{{ options[0].value }}">
    <div class="only-option">
      <svg-icon class="leadingIcon navIcon" src="{{ config.leadIcon }}"></svg-icon>
      <div class="option">
        {{ options[0].value }}
      </div>
    </div>
  </div>
}
