export function formatNumber(x: number) {
  if (isNaN(x)) {
    return 'N/A';
  }

  const ranges = [
    { divider: 1e18, suffix: 'Z' },
    { divider: 1e15, suffix: 'Q' },
    { divider: 1e12, suffix: 'T' },
    { divider: 1e9, suffix: 'B' },
    { divider: 1e6, suffix: 'M' },
    { divider: 1e3, suffix: 'K' },
  ];
  for (const range of ranges) {
    if (x >= range.divider && x >= 10000) {
      return (Math.round((x / range.divider) * 10) / 10).toLocaleString() + range.suffix;
    }
  }
  return x.toLocaleString();
}
