import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';

export class OverTimeMetricValue implements Deserializable<JsonObject, OverTimeMetricValue> {
  constructor(
    public date?: string,
    public count?: number,
    public cumulativeCount?: number,
    public eventTitle?: string
  ) {}

  deserialize(this: OverTimeMetricValue, json: JsonObject): OverTimeMetricValue {
    if (json == null) {
      return null;
    }
    this.date = json['date'] as string;
    this.count = json['count'] as number;
    this.cumulativeCount = json['cumulativeCount'] as number;
    this.eventTitle = json['eventTitle'] as string;
    return this;
  }
}
