import { Deserializable } from './deserializable.model';
import { JsonObject } from './json-object.model';
import { OverTimeMetricValue } from './over-time-metric-value.model';

export class OverTimeMetric implements Deserializable<JsonObject, OverTimeMetric> {
  constructor(
    public data?: OverTimeMetricValue[],
    public metric?: string,
    public metricDesc?: string
  ) {}

  deserialize(this: OverTimeMetric, json: JsonObject): OverTimeMetric {
    if (json == null) {
      return null;
    }
    this.data = (json['data'] as OverTimeMetricValue[])?.map((overTimeMetricValue) =>
      new OverTimeMetricValue().deserialize(overTimeMetricValue)
    );
    this.metric = json['metric'] as string;
    this.metricDesc = json['metricDesc'] as string;
    return this;
  }
}
